//FIXED NAVIGATION ON SCROLL
jQuery(document).scroll(function() {
    var y = jQuery(this).scrollTop();
      if (y > 1) {
        jQuery('#lc-header').addClass("fixed");
      } else {
        jQuery('#lc-header').removeClass("fixed");
      } 
});

jQuery(".optionen_praevention .col").eq(0).addClass("selected");

jQuery(".praevention_wraper_images img").eq(1).fadeOut();

//SWITCH PHONES
var switchElement = jQuery("#switch_telefone a");
var switchElement_mobile = jQuery("#switch_telefone_mobile a");
switchElement.removeAttr('href');
var telefoneWraper = jQuery(".telefone_wrap a");


switchElement.each(function(index) {
    jQuery(this).on("click", function(){
        var mailSwitch = (jQuery(this).attr("data-mail"));

        telefoneWraper.eq(index).siblings("a").hide();
        telefoneWraper.eq(index).show();

        
        
        jQuery(".mail-wrap-switch").attr("href", "mailto:" + mailSwitch);

    });
});

switchElement_mobile.each(function(index) {
    jQuery(this).on("click", function(){
        var mailSwitch = (jQuery(this).attr("data-mail"));

        telefoneWraper.eq(index).siblings("a").hide();
        telefoneWraper.eq(index).show();

        jQuery(".mail-wrap-switch").attr("href", "mailto:" + mailSwitch);

    });
});


jQuery(".navbar-toggler").on("click", function(){
    jQuery(this).find("span").toggleClass("open");
})

jQuery("#currentYear").text( (new Date).getFullYear() );

jQuery(".openClose").each(function(index) {
    jQuery(this).on("click", function(){
        jQuery(this).toggleClass("opened");
        jQuery(this).next().toggleClass("d-none");
        jQuery(this).parent().siblings().find("span").removeClass("opened");
        jQuery(this).parent().siblings().find("span").next().addClass("d-none");
    });
});

jQuery(".optionen_praevention .col").each(function(index) {
    jQuery(this).on("click", function(){
        jQuery(".optionen_praevention .col").removeClass("selected");
        jQuery(this).addClass("selected");
        jQuery(".praevention_wraper_images img").fadeOut();
        jQuery(".praevention_wraper_images img").eq(index).fadeIn();
    });
});


